import axios from 'axios';
import { isBrowser } from './Functions';
import ls from 'local-storage'
import cookie from 'react-cookies'
import { navigate } from "gatsby"

export const getUser = () => {
  //Move from LS to Cookie
  //ls.set('gatsbyUser', 'test')
  if (ls.get('gatsbyUser')) {
    localStorage.clear();
  }

  return isBrowser && cookie.load('gatsbyUser')
    ? cookie.load('gatsbyUser')
    : {}
}

export const postCheck = () => {
  const user = getUser()

  const userApi = () => {
    if (!isBrowser) return false
    const url = `${process.env.WP_ADDRESS}/wp-json/wp/v2/users/validate?cookie=${user.cookie}`
    //console.log('User url:',url)
    return axios.get(url)
  }

  userApi()
  .then( response => {
      if (response.data.status === 'ok' && response.data.valid === true) {
        //console.log(`Cookie matches.`)
        //console.log('Login post check.',response)
        ls.set('dataCount', response.data.dataCount)
      } else {
        logout()
      }
  })
  .catch(error => {
    console.log(error)
    logout()
  })
}

export const isLoggedIn = () => {
  if (!isBrowser) return false
  const user = getUser()
  //console.log('user check:',user)
  if (!user.cookie) {
    console.log('Please login.')
    return false
  }else{
    return true
  }
}

export const getCurrentUser = () => {
  return isBrowser && getUser()
}

export const setUser = (user) => {
  if (!isBrowser) return false
  cookie.save('gatsbyUser', user, { path: '/' })
  //navigate(direct)
}

export const logout = () => {
  if (!isBrowser) return
  console.log('You have been logged out')
  setUser({})
  navigate(`/login`)
}
