import React from 'react';

export const FavIcon = ( props ) => {
  const {url} = props;
  const urlCheck = url.includes("http://") || url.includes("https://") ? url : 'https://dash.distl.com.au'
  if (url) {
    return (
      <img className="favicon" alt="" src={`https://tools.birdbrain.com.au/favicons/?url=${urlCheck}`} />
    )
  }
}
